<template lang="pug">
div
  .ma-2
    v-flex(v-if="loaded", md12)
      v-card.mx-2.px-2
        bar-chart.chart(:chartdata="datacollection", :options="options")

    DataFilter.mt-2(:filterProps="filters", :items="dados", :paginate="false")
      template(v-slot:actions)
        v-divider(vertical)
        .ml-0.mr-0(style="width: 150px")
          v-select(
            label="Itens por página",
            :items="[10, 20, 50, 'Todos']",
            v-model="itemsPerPage",
            max-width="50"
          )
        .ml-0.mr-0(style="width: 120px")
          v-checkbox(label="Disponivel", v-model="available")
        .ml-0.mr-0(style="width: 100px") 
          |
          |
          |
          |
          v-checkbox(label="Atuais", v-model="current")

        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn.ml-2.mr-2(
              color="primary",
              v-on="on",
              @click="getDados()",
              fab,
              small
            )
              v-icon(small) fa-solid fa-rotate-right
          span Atualizar

      template(v-slot:default="{ items }")
        v-data-table(
          dense,
          :headers="headers",
          :items="items",
          hide-default-footer,
          :items-per-page="items.length"
        )
          template(v-slot:header.disponivel="{ header }")
            span {{ header.text }}

          template(v-slot:item.versao="{ item }")
            router-link(
              :to="`/versoes/detalhes/${item['idversao']}?versaodesc=${item['versao']}`",
              tag="a"
            ) {{ item["versao"] }}

          template(v-slot:item.disponivel="{ item }")
            v-icon(:color="item['disponivel'] ? 'success' : 'error'") {{ item["disponivel"] ? "fa-check" : "fa-close" }}
            |
            | {{ item["disponivel"] == null ? "X" : item["disponivel"] }} - {{ item["disponivel"] ? "Disponível" : "Indisponível" }}

          template(v-slot:item.actions="{ item }")
            v-tooltip.ma-0.pa-0(top)
              template(v-slot:activator="{ on, attrs }")
                v-icon(color="primary", v-bind="attrs", v-on="on") fa-comment
              span {{ item["motivo"] }}

            v-btn(
              icon,
              color="success",
              :disabled="item['disponivel'] == null || (!isAdmin() && item['disponivel'] != null)",
              @click="selectVersao(item)"
            )
              v-icon fa-edit

  v-dialog(
    width="800px",
    v-model="showDialog",
    persistent,
    transition="dialog-transition"
  )
    v-card(v-if="versaoSelecionada && showDialog")
      v-card-title {{ versaoSelecionada.versao }}
      v-card-subtitle {{ versaoSelecionada.motivo ? "Ultimo motivo: " + versaoSelecionada.motivo : "" }}
      v-layout.mx-3(row, align-center, wrap)
        v-flex(md2)
          v-combobox(
            solo,
            dense,
            label="Versão disponível",
            item-text="text",
            item-value="value",
            :items="stateItems",
            :value="versaoSelecionada.disponivel ? 'Disponível' : 'Indisponível'",
            @change="setDisponivel"
          )
        v-flex.ml-2
          v-text-field(dense, label="Motivo", v-model="motivo")
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error", @click="resetMotivoVersao")
          v-icon(left) fa-close
          span Cancelar

        v-btn(color="success", @click="alterarDisponibilidade")
          v-icon(left) fa-check
          span Confirmar
</template>

<script>
import { mapMutations } from "vuex";
import { versoes as api } from "@/api";

export default {
  name: "versoes",

  async setup() {
    await this.getDados({ page: 1, limit: 20, available: true, current: true });
  },

  data: () => ({
    filters: {
      includeSearch: true,
      items: [],
    },
    itemsPerPage: 10,
    available: true,
    current: true,
    loaded: false,
    versaoSelecionada: null,
    dados: [],
    fields: api.fields,
    order: api.order,

    datacollection: {
      labels: [],
      datasets: [{ label: "Clientes", backgroundColor: "#456abc", data: [] }],
    },

    options: {
      responsive: true,
      maintainAspectRatio: false,
    },

    headers: [
      { value: "versao", text: "Versão" },
      {
        value: "clientes",
        text: "Qtd Clientes Atualizados",
        width: 250,
        align: "end",
      },
      {
        value: "atuais",
        text: "Qtd Clientes Atual",
        width: 200,
        align: "end",
      },
      {
        value: "erros",
        text: "Erros",
        width: 200,
        align: "end",
      },
      { value: "disponivel", text: "Disponível" },
      { value: "actions", text: "#", sortable: 0 },
    ],

    stateItems: [
      { value: 1, text: "Disponível" },
      { value: 0, text: "Indisponível" },
    ],

    showDialog: false,
    disponivel: null,
    motivo: null,
  }),

  created() {
    this.$store.dispatch("auth/verify");
    this.permissionsMenuSySafra();
  },

  async mounted() {
    this.setTitle("Versões");
    this.isControl(true);
    await this.getDados();
  },

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),
    setDisponivel(item) {
      this.disponivel = typeof item === "object" ? item.value : null;
    },

    selectVersao(versao) {
      this.versaoSelecionada = versao;
      this.showDialog = true;
      this.disponivel = versao.disponivel;
    },

    async alterarDisponibilidade() {
      if (this.disponivel == null) {
        return this.$store.dispatch(
          "alert/error",
          "Disponibilidade não selecionada"
        );
      }

      if (!this.motivo) {
        return this.$store.dispatch("alert/error", "Motivo não informado");
      }

      await api.alterarDisponibilidade(this.versaoSelecionada.idversao, {
        disponivel: this.disponivel,
        motivo: this.motivo,
      });

      this.resetMotivoVersao();

      await this.getDados();
    },

    resetMotivoVersao() {
      this.showDialog = false;
      this.versao = null;
      this.disponivel = null;
      this.motivo = null;
    },

    isAdmin() {
      const roles = JSON.parse(
        JSON.stringify(this.$store.state.auth.user.roles)
      );
      const adminRole = [];

      roles.forEach((role) => {
        if (role.name.toUpperCase() === "GERENTE") {
          adminRole.push(role);
        }
      });

      return adminRole.length > 0 || process.env.NODE_ENV === "development";
    },

    async getDados() {
      this.showLoader();
      api.refreshAuthProd(this.$store.state.auth);
      try {
        this.dados = await api.getAll({
          page: 1,
          limit: this.itemsPerPage,
          available: this.available,
          current: this.current,
        });

        this.dados.map((versao) => {
          versao.clientesDesc = versao.clientes === 1 ? "cliente" : "clientes";
          versao.errosDesc = versao.erros === 1 ? "erro" : "erros";
        });

        this.datacollection.labels = this.dados
          .slice(0, 20)
          .map(({ versao }) => versao);
        // .reverse();
        this.datacollection.datasets[0].data = this.dados
          .slice(0, 20)
          .map(({ clientes }) => clientes);
        // .reverse();
        this.loaded = true;
      } catch (e) {
        this.$store.dispatch(
          "alert/error",
          "Ocorreu algum problema ao buscar as informações",
          { root: true }
        );
      } finally {
        this.hideLoader();
      }
    },

    permissionsMenuSySafra() {
      if (this.$store.state.auth.user === null) return false;

      const roles = JSON.parse(
        JSON.stringify(this.$store.state.auth.user.roles)
      );
      this.$store.state.app.accessSySafra =
        roles.filter((role) => role.projectid == 250).length > 0;
      this.enableSysafra = this.$store.state.app.accessSySafra;
    },
  },

  computed: {
    filteredData() {
      const filters = this.search.split("|");
      let data = this.dados;

      for (const filter of filters) {
        const itemFilter = filter.split(":");

        if (itemFilter.length < 2) {
          break;
        }

        data = data.filter(
          (item) =>
            `${item[itemFilter[0].trim()]}`.indexOf(itemFilter[1].trim()) > -1
        );
      }

      return data;
    },
  },
};
</script>

<style>
.dv-versao {
  margin: 0;
  padding: 0;
  width: 100%;
  border-block-end: 1px solid #313341;
  max-height: 45px;
}

.dv-versao:hover {
  background-color: #f0f0f0;
}

.chart {
  max-height: 250px;
}

.btn-versao {
  margin: 0;
  padding: 0;
}
</style>
